import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

import Canvg, { presets } from "canvg"

import { useQueryParam, StringParam } from "use-query-params"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IllustDownload = ({ data }) => {
  const OFF_SCREEN_CANVAS_ID = "svgOffScreeenRenderCanvas"
  const OFF_SCREEN_CANVAS_CLASS = "svg-off-screen-render-canvas"

  const intl = useIntl()
  const illusts = data.illusts

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [generateSize, setGenerateSize] = useState([1280, 790])
  const [pngIconData, setPngIconData] = useState("")
  const [isIconRendered, setIsIconRendered] = useState(false)

  const [q, setQ] = useQueryParam("q", StringParam)
  let svgItems = []
  const [tags, setTags] = useState([])

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
        changeColor(e.target.value)
      },
    }
  }

  const pickedColor = useInput("")

  const colorPallete = [
    "#DCDCCF",
    "#C35B5D",
    "#DC965F",
    "#F0CB87",
    "#74BEAE",
    "#75B0D2",
    "#9380B5",
    "#4E5C73",
    "#FFFFFF",
  ]

  function fetchSvg(name) {
    fetch("/images/illustrations/" + name + ".svg")
      .then(response => response.text())
      .then(resultData => {
        setSvgText(resultData)
      })
  }

  const [svgText, setSvgText] = useState("")
  useEffect(() => {
    fetchSvg(q)
    fetchTag(q)
  }, [])

  const changeColor = newColor => {
    const regex = /<rect x="0" y="0" width="680" height="420" style="fill:.*?;"\/>/gi
    const after =
      '<rect x="0" y="0" width="680" height="420" style="fill:' +
      newColor +
      ';"/>'
    setSvgText(svgText.replace(regex, after))
  }

  function setSvgData() {
    return "data:image/svg+xml;charset=utf8," + encodeURIComponent(svgText)
  }

  const [isCopied, setIsCopied] = useState(false)
  function writeToClipboard() {
    const regex = /<\?xml .*?svg11.dtd">/gi
    const copyText = svgText.replace(regex, "")
    navigator.clipboard.writeText(copyText).then(() => {
      setIsCopied(true)
    })
  }
  let clipText = isCopied
    ? intl.formatMessage({ id: "download.copied" })
    : intl.formatMessage({ id: "download.copy2clip" })

  function setQuery(name) {
    setQ(name)
    fetchSvg(name)
    fetchTag(name)
  }

  function fetchTag(name) {
    fetch("/data/svgItems.json")
      .then(response => response.json())
      .then(data => {
        svgItems = data
        setTag(name)
      })
  }

  function setTag(word) {
    const result = svgItems.find(({ name }) => name === word)
    setTags(result.tag)
  }

  function modalOpen() {
    setIsIconRendered(false)
    setGenerateSize([1280, 790])
    setIsModalOpen(true)
    window.scrollTo(0, 0)
  }

  function generatePng() {
    svg2png(svgText, generateSize[0], generateSize[1])
  }

  const svg2png = async (text, width, height) => {
    const canvas = createOffScreenCanvas(width, height)
    const ctx = canvas.getContext("2d")
    const v = await Canvg.from(ctx, text, presets.offscreen())
    await v.render()
    const data = canvas.toDataURL("image/png")
    setPngIconData(data)
    setIsIconRendered(true)
    canvas.remove()
    return data
  }

  //キャンバスの作成
  function createOffScreenCanvas(width, height) {
    var newCanvas = document.createElement("canvas")
    newCanvas.setAttribute("id", OFF_SCREEN_CANVAS_ID)
    newCanvas.setAttribute("width", width)
    newCanvas.setAttribute("height", height)

    //styleの設定
    var style = newCanvas.style
    style.position = "absolute"
    style.left = "-9999px"
    style.top = "0px"

    newCanvas.classList.add(OFF_SCREEN_CANVAS_CLASS)
    document.querySelector("body").appendChild(newCanvas)
    return newCanvas
  }

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.download" })} />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-4 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "download.download" })}
        </h3>
        {/* png生成モーダルウィンドウ */}
        <div className={isModalOpen ? "relative" : "relative hidden"}>
          <div className="z-30 modal-icon-window">
            <div className="w-full h-auto">
              <div className="mx-2  md:w-4/5 md:mx-auto p-3 h-auto bg-white border border-gray-400 border rounded">
                <div className="flex justify-between">
                  <div className="font-semibold mb-3 text-lg leading-tight sm:leading-normal">
                    <span>
                      {intl.formatMessage({ id: "download.generatetitle" })}
                    </span>
                  </div>
                  <button
                    className="block h-6 w-6 fill-current hover:bg-gray-300"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fillRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="text-sm">
                  {intl.formatMessage({ id: "download.generatenavi" })}
                  (1~9999px)
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mt-2 text-sm md:text-base">
                  <div
                    className="border border-gray-400 rounded px-1 md:px-2 hover:bg-gray-200 text-center"
                    onClick={() => setGenerateSize([640, 396])}
                  >
                    640×396px
                  </div>
                  <div
                    className="border border-gray-400 rounded px-1 md:px-2 hover:bg-gray-200 text-center"
                    onClick={() => setGenerateSize([1280, 790])}
                  >
                    1280×790px
                  </div>
                  <div
                    className="border border-gray-400 rounded px-1 md:px-2 hover:bg-gray-200 text-center"
                    onClick={() => setGenerateSize([1980, 1224])}
                  >
                    1980×1224px
                  </div>
                </div>
                <div className="text-sm mt-4">
                  {intl.formatMessage({ id: "download.generatestart" })}
                </div>
                <div className="flex flex-wrap mt-2 h-8">
                  <div className="flex h-full">
                    <div className="h-full">
                      <input
                        type="text"
                        placeholder="128"
                        className="w-16 h-full border border-gray-400 focus:outline-none focus:border-gray-500 rounded py-1 px-2 appearance-none"
                        maxLength="4"
                        value={generateSize[0]}
                        onChange={e =>
                          setGenerateSize([
                            e.target.value,
                            Math.floor(e.target.value / 1.62),
                          ])
                        }
                      ></input>
                    </div>
                    <div className="h-full flex flex-col justify-center mx-1">
                      ×
                    </div>
                    <div className="">
                      <input
                        type="text"
                        placeholder="128"
                        disabled="disabled"
                        className="w-16 h-full border border-gray-400 bg-gray-300 rounded py-1 px-2 appearance-none"
                        value={generateSize[1]}
                      ></input>
                    </div>
                    <div className="h-full flex flex-col justify-center mx-1">
                      px
                    </div>
                  </div>
                  <div
                    onClick={() => generatePng()}
                    className="w-auto h-full ml-4 text-sm cursor-pointer bg-green-600 hover:bg-green-500 text-green-100 px-4 md:px-20 rounded inline-flex flex-col justify-center items-center border border-gray-400"
                  >
                    {intl.formatMessage({ id: "download.generate" })}
                  </div>
                </div>
                <div className={isIconRendered ? "" : "hidden"}>
                  <div className="font-semibold mt-4 mb-2 leading-tight sm:leading-normal">
                    <span>
                      {intl.formatMessage({ id: "download.complete" })}
                    </span>
                  </div>
                  <p className="text-sm pb-2 hidden md:block">
                    {intl.formatMessage({ id: "tools.generator.downloadDesc" })}
                  </p>
                  <p className="text-sm pb-2 block md:hidden">
                    {intl.formatMessage({
                      id: "tools.generator.downloadDescSp",
                    })}
                  </p>
                  <div className="w-full rounded p-2 transparentBg border border-gray-400">
                    <div className="h-20 md:h-40 flex justify-center items-center">
                      <img
                        src={pngIconData}
                        alt="png data"
                        className="max-h-full"
                      ></img>
                    </div>
                  </div>
                  <div className="w-full flex justify-center mt-3">
                    <a
                      download={"flat-svg-designs-" + q + ".png"}
                      href={pngIconData}
                      className="w-1/2 h-8 text-sm cursor-pointer bg-green-600 hover:bg-green-500 text-green-100 px-4 md:px-20 rounded inline-flex flex-col justify-center items-center border border-gray-400"
                    >
                      {intl.formatMessage({ id: "download.download" })}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div
              className="w-full h-full rounded transparentBg border border-gray-400"
              dangerouslySetInnerHTML={{
                __html: svgText,
              }}
            ></div>
            <div className="w-full h-full">
              <div className="text-base w-full mb-2">
                <span role="img" aria-label="point">
                  👇
                </span>
                {intl.formatMessage({ id: "download.selectbg" })}
              </div>
              <div className="flex h-10 mb-3">
                {colorPallete.map(color => (
                  <label className="cursor-pointer p-1">
                    <input
                      {...pickedColor}
                      type="radio"
                      value={color}
                      className="hidden"
                      name="selectColor"
                    />
                    <div className="rounded-full border border-gray-400 hover:shadow">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 200 200"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="100"
                          cy="100"
                          r="100"
                          style={{ fill: color }}
                        />
                      </svg>
                    </div>
                  </label>
                ))}
              </div>
              <div className="flex h-10">
                <input
                  type="text"
                  placeholder="#FFFFFF"
                  className="rounded w-1/2 pl-3 border border-gray-400 appearance-none"
                  {...pickedColor}
                />
                <label>
                  <input
                    {...pickedColor}
                    type="radio"
                    value="rgba(0,0,0,0)"
                    className="hidden"
                    name="selectColor"
                  />
                  <div className="h-full w-1/4 ml-6 px-3 rounded transparentBg border border-gray-400 cursor-pointer"></div>
                </label>
              </div>
              <div className="grid grid-cols-2 h-20 gap-3 mt-3">
                <a
                  download={"flat-svg-designs-icon-" + q + ".svg"}
                  href={setSvgData()}
                  className=""
                >
                  <button className="w-full h-full text-sm cursor-pointer bg-teal-600 hover:bg-teal-500 text-teal-100 py-2 px-4 rounded inline-flex flex-col justify-center items-center border border-gray-400">
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "download.svg" })}
                      <br />
                      {intl.formatMessage({ id: "download.download" })}
                    </p>
                  </button>
                </a>
                <div onClick={() => modalOpen()}>
                  <button className="w-full h-full text-sm cursor-pointer bg-green-600 hover:bg-green-500 text-green-100 py-2 px-4 rounded inline-flex flex-col justify-center items-center border border-gray-400">
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "download.png" })}
                      <br />
                      {intl.formatMessage({ id: "download.download" })}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 pb-3 flex flex-wrap items-center">
            <div className="mr-2 fill-current mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path d="M2.59 13.41A1.98 1.98 0 0 1 2 12V7a5 5 0 0 1 5-5h4.99c.53 0 1.04.2 1.42.59l8 8a2 2 0 0 1 0 2.82l-8 8a2 2 0 0 1-2.82 0l-8-8zM20 12l-8-8H7a3 3 0 0 0-3 3v5l8 8 8-8zM7 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
            </div>
            {tags.map((tag, index) => (
              <div
                key={index}
                className="mr-2 mb-1 px-1 text-sm rounded text-white bg-gray-700 border border-gray-400"
              >
                <Link to={"/query?q=" + tag}>{tag}</Link>
              </div>
            ))}
          </div>
        </div>
        <h3 className="font-semibold text-xl mb-3">
          {intl.formatMessage({ id: "download.relatedillust" })}
        </h3>
        <div className="images grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-3 mb-6">
          {illusts.edges.map(({ node }, index) => (
            <div
              key={index}
              className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow cursor-pointer"
              onClick={() => setQuery(node.name)}
            >
              <img
                src={"/images/" + node.cat + "/" + node.name + ".svg"}
                alt={node.tag}
                loading="lazy"
              ></img>
            </div>
          ))}
          <div className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow">
            <Link to="/illustrations/">
              <div className="h-full w-full py-6 text-center font-semibold flex flex-col justify-center">
                More...
              </div>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IllustDownload

export const query = graphql`
  query {
    illusts: allSvgItemsJson(
      sort: { fields: id, order: DESC }
      filter: { cat: { eq: "illustrations" } }
      limit: 11
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
  }
`
